import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  introSection,
  // description,
  row,
  // col6,
  col12,
  //marginTopSubstitute,
} from "./subpage.module.scss";

const DatenschutzPage = () => {
  return (
    <Layout pageTitle="Datenschutz" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>Datenschutz&shy;erklärung</h1>
                {/* <p className="text-medium"></p> */}
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3 style={{ marginTop: 16 }}>
                  1. Verantwortlicher und Datenschutzbeauftragter
                </h3>
                <p>
                  Diese Datenschutzerklärung informiert die Nutzer dieser
                  Webseite über die Art, den Umfang und den Zweck der Erhebung
                  und Verwendung von personenbezogenen Daten durch den Betreiber
                  der Webseite tie.ch
                </p>
                <p>
                  Wir nehmen Ihren Datenschutz sehr ernst und behandeln Ihre
                  personenbezogenen Daten stets vertraulich und entsprechend der
                  gesetzlichen Vorschriften. Aufgrund neuer Technologien und der
                  ständigen Weiterentwicklung dieser Webseite, können Änderungen
                  an dieser Datenschutzerklärung vorgenommen werden. Daher
                  empfehlen wir, dass Sie unsere Datenschutzerklärung in
                  regelmäßigen Abständen besuchen.
                </p>
                <p>Stand der Datenschutzerklärung: 14.12.2020</p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  1.1 Der Verantwortliche im Sinne des Art. 4 Nr. 7 DSGVO ist:
                </h3>
                <p>
                  the i-engineers AG
                  <br />
                  Badenerstrasse 141
                  <br />
                  CH-8004 Zürich
                </p>
                <p>
                  Die vertretungsberechtigte Person ist der Geschäftsführer
                  Severin Summermatter. Der Verantwortliche hat seinen Sitz in
                  Zürich (Gerichtsstand Zürich, UID CHE-109.863.172).
                </p>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  1.2 Datenschutzbeauftragter
                </h3>
                <p>
                  In allen Fragen zum Datenschutz, die unsere Webseite und unser
                  Dienstleistungsangebot betreffen oder zur Wahrnehmung Ihrer
                  Personenrechte wenden Sie sich bitte an unseren
                  Datenschutzbeauftragen:
                </p>
                <p style={{ marginBottom: 64 }}>
                  Prof. Dr. Thomas Jäschke
                  <br />
                  Datatree AG
                  <br />
                  Märkische Straße 212-218
                  <br />
                  44141 Dortmund
                  <br />
                  E-Mail: dsb@datatree.ag
                  <br />T +49 231 54380-798
                </p>

                <h3 style={{ marginTop: 16 }}>2. Rechte des Betroffenen</h3>
                <p>
                  Sie können Ihre Rechte zum Datenschutz jederzeit und
                  unentgeltlich in Anspruch nehmen. Unser Datenschutzbeauftragte
                  überprüft und beantwortet jedes Anliegen individuell. Seine
                  Kontaktdaten finden Sie unter dem{" "}
                  <span className="text-blue">Punkt 1.2.</span>
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  2.1 Recht auf Auskunft gem. Art. 15 DSGVO / Art. 25 DSG
                </h3>
                <p>
                  Sie haben das Recht jederzeit unentgeltlich Auskunft darüber
                  zu erhalten, ob wir Daten zu Ihrer Person verarbeiten.
                </p>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  2.2 Recht auf Berichtigung gem. Art. 16 DSGVO / Art. 32 DSG,
                  Recht auf Löschung gem. Art. 17. DSGVO, Recht auf
                  Einschränkung der Verarbeitung gem. Art. 18 DSGVO / Art. 26
                  DSG, Recht auf Datenübertragung gem. Art. 20 DSGVO / Art. 28
                  DSG und das Recht auf Widerspruch gem. Art. 21 DSGVO
                </h3>
                <p>
                  Weiterhin haben Sie die Möglichkeit die Rechte auf
                  Berichtigung, Löschung oder Einschränkung der Verarbeitung
                  geltend zu machen. Sie können auch jederzeit Widerspruch gegen
                  die Verarbeitung Ihrer Daten gegenüber uns einlegen.
                </p>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  2.3 Widerruf im Falle einer Einwilligung gem. Art. 7 Abs. 3
                  DSGVO
                </h3>
                <p>
                  Sofern wir Ihre personenbezogenen Daten auf Grundlage einer
                  Einwilligung verarbeiten, haben sie das Recht die Einwilligung
                  jederzeit für die Zukunft zu widerrufen. Ihr Widerruf gilt
                  allerdings erst ab dem Zeitpunkt, zu dem Sie diesen
                  aussprechen und hat keine Rückwirkung. Die Verwendung Ihrer
                  Daten bis zu diesem Zeitpunkt bleibt rechtmäßig.
                </p>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  2.4 Ihr Recht auf Beschwerde bei der Aufsichtsbehörde
                </h3>
                <p>
                  Sofern Sie der Meinung sind, die Verarbeitung Ihrer
                  personenbezogenen Daten durch die the i-engineers AG erfolge
                  nicht rechtmäßig, haben Sie das jederzeitige Recht, sich mit
                  Ihrer Beschwerde an jede Datenschutzaufsichtsbehörde zu wenden
                  (gem. Art. 55 DSGVO). Die zuständige Aufsichtsbehörde ist die:
                </p>

                <p style={{ marginBottom: 64 }}>
                  {" "}
                  <span className="text-blue">
                    Datenschutzbeauftragte des Kantons Zürich
                  </span>
                  <br />
                  Beckenhofstrasse 23
                  <br />
                  Postfach
                  <br />
                  8090 Zürich
                  <br />
                  <br />
                  Weitere Informationen und die aktuellen Kontaktdaten entnehmen
                  Sie bitte der Webseite des{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://datenschutz.ch/"
                  >
                    (https://www.zh.ch/de/politik-staat/datenschutz.html).
                  </a>
                </p>
                <p>
                  {" "}
                  <span className="text-blue">Nordrhein-Westfalen</span>
                  <br />
                  Landesbeauftragte für Datenschutz und Informationsfreiheit
                  Nordrhein-Westfalen
                </p>
                <p>
                  Bettina Gayk <br />
                  Kavalleriestr. 2-4 <br />
                  40102 Düsseldorf <br />
                  T +49 211 384240 <br />
                  E-Mail: poststelle@ldi.nrw.de{" "}
                </p>

                <p>
                  Weitere Informationen und die aktuellen Kontaktdaten entnehmen
                  Sie bitte der Webseite des{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.ldi.nrw.de/"
                  >
                    www.ldi.nrw.de
                  </a>
                </p>

                <h3 style={{ marginTop: 16 }}>
                  3. Verarbeitung Ihrer Daten bei Nutzung dieser Webseite
                </h3>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  3.1 Protokollierung / Logging
                </h3>
                <p>
                  Sofern Sie unsere Webseite besuchen, werden durch den auf
                  Ihrem Endgerät zum Einsatz kommenden Browser automatisch
                  Informationen an den Server unserer Webseite übermittelt.
                  Diese Informationen werden temporär in einer sog. Logfile
                  gespeichert.
                </p>
                <p>Folgende Informationen werden ohne Ihr Zutun gespeichert:</p>
                <ul>
                  <li>IP-Adresse des anfragenden Rechners,</li>
                  <li>
                    Webseite, von der aus der Zugriff erfolgt (sog.
                    Referrer-URL){" "}
                  </li>
                  <li>
                    Browsertyp und -version und weitere durch den Browser
                    übermittelte Informatio-nen (z.B. das Betriebssystem Ihres
                    Computers) und die Sprache
                  </li>
                  <li>Datum und Uhrzeit der Anfrage </li>
                  <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                  <li>Zugriffsstatus / HTTP-Statuscode</li>
                  <li>die jeweils übertragene Datenmenge</li>
                </ul>
                <p>
                  Hierbei erfolgt die Datenverarbeitung gem. Art. 6 Abs. 1 lit.
                  f DSGVO i.V.m. § 25 Abs. 2 Nr. 2 TTDSG / Art. 31 DSG, um einen
                  störungsfreien Betrieb unserer Seiten sicherzustellen und die
                  Systemsicherheit und -stabilität auszuwerten. Die erhobenen
                  Daten werden nicht dazu verwendet, Rückschlüsse auf Ihre
                  Person zu ziehen.
                </p>
                <p style={{ marginBottom: 64 }}>
                  Die gespeicherten Daten werden für maximal 360 Tage in den
                  Logfiles gespeichert und anschließend gelöscht. Daten, deren
                  weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind
                  bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                  Löschung ausgenommen.
                </p>

                <h3 style={{ marginTop: 16 }}>
                  4. Allgemeiner Teil der Datenschutzerklärung
                </h3>
                <h3 style={{ marginTop: 16, color: "black" }}>
                  4.1 Newsletter / Anmeldeformular für Events{" "}
                </h3>
                <p>
                  Sie können sich zu Events oder zu unserem Newsletter anmelden.
                  Die Anmeldung zu unserem Newsletter erfolgt durch den Button
                  Einsenden.
                </p>
                <p>
                  the i-engineers AG verpflichtet sich, Ihre Privatsphäre zu
                  schützen und zu respektieren. Wir verwenden Ihre persönlichen
                  Daten nur zur Verwaltung Ihres Kontos und zur Bereitstellung
                  der von Ihnen angeforderten Produkte und Dienstleistungen. Von
                  Zeit zu Zeit möchten wir Sie über unsere Produkte und
                  Dienstleistungen sowie andere Inhalte, die für Sie von
                  Interesse sein könnten per E-Mail informieren.
                </p>
                <p>
                  Indem Sie unten auf „Einsenden“ klicken, stimmen Sie zu, dass
                  the i-engineers AG die oben angegebenen persönlichen Daten
                  speichert und verarbeitet, um Ihnen die angeforderten Inhalte
                  bereitzustellen.
                </p>
                <p>
                  Betreiber dieses Dienstes ist Sendinblue GmbH (nachfolgend
                  „Brevo“ genannt), Köpenicker Straße 126, 10179 Berlin,
                  Deutschland. Durch Anmeldung zu dem Newsletter oder zu Events
                  erhalten sowohl wir als auch Brevo Ihre E-Mail-Adresse, Name,
                  Vorname, Unternehmensname und Ihre Jobbezeichnung. Ferner
                  werden Ihre IP-Adresse und das Datum Ihrer Anmeldung
                  gespeichert. Schließlich wird beim Anmeldevorgang Ihre
                  Einwilligung in die Übersendung des Newsletters eingeholt und
                  auf die Datenschutzerklärung verwiesen.
                </p>
                <p>
                  Näheres zum Zweck und Umfang der Datenerhebung und der
                  weiteren Verarbeitung dieser Daten durch Brevo sowie
                  diesbezügliche Rechte und Einstellungsmöglichkeiten zum Schutz
                  Ihrer Privatsphäre können Sie der Datenschutzrichtlinie
                  entnehmen unter:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brevo.com/de/legal/termsofuse/"
                  >
                    https://www.brevo.com/de/legal/termsofuse/{" "}
                  </a>
                </p>
                <p>
                  Grundlage dieser Verarbeitungen und der Übermittlung an Brevo
                  in diesem Rahmen ist die Einwilligung gem. Art. 6 Abs.1 f)
                  DSGVO/ Art. 31 DSG. Selbstverständlich können Sie uns auch auf
                  anderem Wege kontaktieren, beispielsweise per Telefonanruf,
                  E-Mail oder über unser Kontaktformular auf der Webseite, wie
                  weiter oben in diesem Abschnitt beschrieben.
                </p>
                <p>
                  Wir möchten Sie dahingehend informieren, dass Sie Ihre
                  Einwilligung in den Newsletter-Versand jederzeit mit Wirkung
                  für die Zukunft widerrufen können, Art 7 III DS-GVO. Sofern
                  Sie diese Möglichkeit des Widerrufs wahrnehmen möchten, setzen
                  Sie sich mit uns in Verbindung oder nutzen Sie den in jedem
                  Newsletter enthaltenen Abmeldelink.
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>
                  4.2 Kontaktanfrage / Kontaktformular
                </h3>
                <p>
                  Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
                  Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
                  E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
                  gespeichert, um Ihre Fragen zu beantworten. Die Angabe
                  weiterer Daten Ihrerseits bleibt stets freiwillig.{" "}
                </p>
                <p>
                  Die Rechtsgrundlage zur Bearbeitung Ihrer Kontaktanfrage und
                  deren Abwicklung ist im Falle vorvertraglicher bzw. bereits
                  vertraglicher Beziehung Art. 6 Abs. 1 lit. b DSGVO / Art. 31
                  DSG oder im Falle anderer Anfragen unser berechtigtes
                  Interesse Ihnen zu antworten, Art. 6 Abs. 1 lit. f DSGVO/ Art.
                  31 DSG.
                </p>
                <p>
                  Die Speicherung Ihrer Angaben erfolgt aufgrund Ihrer
                  Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO/ Art.31 DSG, die
                  Sie uns mit Abschicken Ihrer Kontaktanfrage, erteilt haben.
                  Die in diesem Zusammenhang anfallenden Daten löschen wir,
                  nachdem die Speicherung nicht mehr erforderlich ist und der
                  Löschung keine gesetzlichen Aufbewahrungspflichten
                  entgegenstehen (z.B. im Falle einer anschließenden
                  Vertragsabwicklung).
                </p>
                <p>
                  Da Sie eine Einwilligung abgegeben haben, möchten wir Sie
                  dahingehend informieren, dass sie Ihre abgegebene Einwilligung
                  bei der Kontaktanfrage jederzeit und ohne Angaben von Gründen
                  mit Wirkung für die Zukunft widerrufen können, Art. 7 Abs. 3
                  DSGVO. Sofern Sie diese Möglichkeit des Widerrufs wahrnehmen
                  möchten, setzen Sie sich mit uns in Verbindung.
                </p>
                <p>
                  Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre
                  persönlichen Daten, werden diese bei der Datenübertragung per
                  TLS-Technik verschlüsselt.
                </p>

                <h3 style={{ marginTop: 16, color: "black" }}>4.3 YouTube</h3>
                <p>
                  Wir haben YouTube-Videos in unser Online-Angebot eingebunden,
                  die auf{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://www.youtube.com"
                  >
                    {" "}
                    http://www.youtube.com{" "}
                  </a>{" "}
                  gespeichert sind und die durch den Link direkt auf YouTube
                  abspielbar sind. Diese sind alle im „erweiterten
                  Datenschutz-Modus“ eingebunden, d.h., dass keine Daten über
                  Sie als Nutzer:in an YouTube übertragen werden, wenn Sie die
                  Videos nicht abspielen. Erst wenn Sie die Videos abspielen,
                  werden die unten genannten Daten übertragen. Auf diese
                  Datenübertragung haben wir keinen Einfluss.{" "}
                </p>
                <p>
                  Durch den Besuch auf YouTube werden unter anderem folgende
                  personenbezogene Daten an YouTube übermittelt:
                </p>
                <ul>
                  <li>Ihre IP-Adresse</li>
                  <li>das Datum und die Uhrzeit der Anfrage</li>
                  <li>die Zeitzonendifferenz zur Greenwich Mean Time</li>
                  <li>der Inhalt der Anforderung (konkrete Seite) </li>
                  <li>der Zugriffsstatus/HTTP-Statuscode</li>
                  <li>die jeweils übertragene Datenmenge</li>
                  <li>die Webseite, von der die Anforderung kommt</li>
                  <li>
                    Browsertyp, das Betriebssystem und dessen Oberfläche sowie
                    die Sprache und die Version der Browsersoftware
                  </li>
                </ul>

                <p>
                  Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto
                  bereitstellt, über das Sie eingeloggt sind oder ob ein
                  Nutzerkonto besteht oder nicht.{" "}
                </p>
                <p>
                  Sofern Sie ein YouTube-Nutzerkonto und/oder Google-Konto
                  besitzen und eingeloggt sind, kann YouTube Ihr
                  Nutzungsverhalten analysieren und ein Ihrem Nutzungsverhalten
                  entsprechendes Nutzungsprofil erstellen. Dabei werden sog.
                  Cookies auf Ihrem Endgerät gespeichert. Diese Cookies erlauben
                  es YouTube, Nutzerprofile anhand Ihrer Präferenzen und
                  Interessen zu erstellen und Ihnen darauf abgestimmte Werbung
                  (innerhalb und außerhalb der Plattform) anzuzeigen. Wir haben
                  keinen Zugriff auf die Nutzungsdaten, die YouTube zur
                  Erstellung dieser Statistiken erhebt. Die einzelnen
                  Datenverarbeitungsvorgänge und ihr Umfang sind nicht zwingend
                  für uns nachvollziehbar.
                </p>
                <p>
                  Sofern Sie nicht damit einverstanden sind, dass YouTube die
                  gesammelten Daten unmittelbar Ihrem YouTube-Nutzerkonto
                  und/oder Google-Konto zuordnet, empfehlen wir Ihnen, sich vor
                  Anklicken des Videos aus den jeweiligen Konten der oben
                  genannten Portale auszuloggen, Ihre Cookies im Rahmen der
                  Browsereinstellung zu löschen sowie den Browser neu zu
                  starten. Zudem steht Ihnen ein Widerspruchsrecht gegen die
                  Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung
                  dessen an YouTube richten müssen.{" "}
                </p>
                <p>
                  Der Einsatz von YouTube erfolgt gem. Art. 6 Abs. 1 lit. a
                  DSGVO i.V.m. § 25 Abs. 1 TTDSG/ 31 DSG nur in dem Fall, wenn
                  Sie uns Ihre Einwilligung über Anklicken des Links. Sofern Sie
                  nicht zugestimmt haben / Sofern Sie das YouTube-Video nicht
                  abgespielt haben, erfolgt keine Datenverarbeitung.
                </p>
                <p>
                  Sie können Ihre Einwilligung jederzeit gem. Art. 7 Abs. 3
                  DSGVO und ohne Angaben von Gründen mit Wirkung für die Zukunft
                  widerrufen, indem Sie auf den in der Fußzeile unserer
                  Internetseite integrierten Button „Datenschutzeinstellungen
                  anzeigen“ klicken und so den Consent-Banner aufrufen. Anhand
                  des Banners können Sie dann eine erneute Auswahl treffen.
                </p>
                <p>
                  Wir können nicht ausschließen, dass eine Übermittlung von
                  personenbezogenen Daten außerhalb des Rechtsraumes der
                  Europäischen Union, z.B. auf Server mit Standort in den USA
                  erfolgt.{" "}
                  <span className="text-blue">
                    Weiterhin weisen wir ausdrücklich darauf hin, dass für die
                    USA kein gleichwertiges Datenschutzniveau garantiert werden
                    kann.
                  </span>
                </p>
                <p>
                  Einzelheiten über die Erhebung und Speicherung Ihrer
                  personenbezogenen Daten sowie über Art, Umfang und Zweck ihrer
                  Verwendung durch YouTube entnehmen Sie bitte der
                  Datenschutzerklärung von YouTube:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href=" https://policies.google.com/privacy?hl=de"
                  >
                    {" "}
                    https://policies.google.com/privacy?hl=de.{" "}
                  </a>{" "}
                </p>

                <h3 id="etracker" style={{ marginTop: 16, color: "black" }}>
                  4.4 eTracker
                </h3>
                <p>
                  Wir nutzen auf unserer Webseite eTracker, ein Webanaylsedienst
                  der eTracker GmbH, Erste Brunnenstr. 1, 20459 Hamburg.
                  Mithilfe von eTracker können wir die Benutzung unserer
                  Webseite analysieren sowie unsere Webpräsenz
                  interessengerechter gestalten.{" "}
                </p>
                <p>
                  eTracker speichert zur Analyse ihres Nutzerverhaltens Cookies
                  über Ihren genutzten Browser auf Ihrem Endgerät und erhebt
                  insbesondere folgende personenbezogene Daten:{" "}
                </p>
                <ul>
                  <li>wann Sie unsere Webseite besuchen </li>
                  <li>wie lange Sie die Webseite besuchen</li>
                  <li>
                    Herkunftsquelle Ihres Besuches (d.h. über welche Webseite
                    Sie zu uns gekommen sind)
                  </li>
                  <li>
                    technische Informationen (z.B. Informationen über Ihren
                    Browsertyp)
                  </li>
                </ul>

                <p>
                  Der Einsatz von eTracker erfolgt gem. Art. 6 Abs. 1 lit. a
                  DSGVO i.V.m. § 25 Abs. 1 TTDSG/Art. 31 DSG nur in dem Fall,
                  wenn Sie uns Ihre Einwilligung über die Auswahl in unserem
                  Consent-Banner erteilt haben. Sofern Sie nicht zugestimmt
                  haben, erfolgt keine Datenverarbeitung.
                </p>
                <p>
                  Sie können Ihre Einwilligung jederzeit gem. Art. 7 Abs. 3
                  DSGVO und ohne Angaben von Gründen mit Wirkung für die Zukunft
                  widerrufen, indem Sie auf den in der Fußzeile unserer
                  Internetseite integrierten Link „Datenschutzeinstellungen
                  anzeigen“ klicken und so den Consent-Banner aufrufen. Anhand
                  des Banners können Sie dann eine erneute Auswahl treffen.
                </p>
                <p style={{ marginBottom: 64 }}>
                  Einzelheiten über die Erhebung und Speicherung Ihrer
                  personenbezogenen Daten sowie über Art, Umfang und Zweck ihrer
                  Verwendung durch eTracker entnehmen Sie bitte der
                  Datenschutzerklärung von eTracker:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.etracker.com/datenschutzerklaerung/"
                  >
                    {" "}
                    https://www.etracker.com/datenschutzerklaerung/.{" "}
                  </a>
                </p>

                {/*
                         <h3 style={{ marginTop: 16 }}>4.	Allgemeiner Teil der Datenschutzerklärung</h3>
                         <h3 style={{ color:"black" }}>4.1	Cookies</h3>
                         <p >4.1.1	Definition: Temporäre und permanente Cookies</p>
                         <p>Bei Cookies handelt es sich um kleine Textdateien, die von einem Webserver auf
                            Ihrem Computer abgelegt werden. Wenn Sie die Webseite das nächste Mal besuchen, 
                            kann diese anhand des Cookies Ihren Webbrowser wiedererkennen. Cookies können keine Programme 
                            ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt 
                            nutzerfreundlicher und effektiver zu machen.
                            </p>
                            <p>Es gibt verschiedene Varianten von Cookies, deren Umfang und Funktionsweise wir 
                            nun erläutern möchten:</p>
                            <ul>
                      <li>Es gibt die sog. <span className="text-blue">temporären bzw. transiente Cookies</span>, die automatisiert gelöscht werden, 
                        wenn Sie den Browser schließen. Dazu zählen unter anderem die Sessi-on-Cookies. 
                        Diese speichern eine sog. Session-ID, mit welcher sich verschiedene Anfragen Ihres 
                        Browsers einer gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt 
                        werden, wenn Sie auf unsere Webseite zurück-kehren. Die Session-Cookies werden gelöscht, 
                        wenn Sie sich ausloggen oder Ihren Webbrowser schließen.</li>
                      <li>Weiterhin gibt es die <span className="text-blue">permanenten bzw. persistenten</span> Cookies, die zwar zunächst nach dem 
                        Schließen des Browsers gespeichert bleiben, aber automatisiert nach einer vorgegebenen Dauer 
                        gelöscht werden. Die Dauer unterscheidet sich je nach Cookie, wobei wir Sie darauf hinweisen möchten, 
                        dass Sie die Cookies mithilfe der Sicherheitseinstellungen Ihres Webbrowsers jederzeit löschen können.</li>
                      <li>Unter den <span className="text-blue">Third-Party-Cookies </span>versteht man Cookies, die von anderen Anbietern als dem Verantwortlichen, 
                        der das Onlineangebot betreibt, angeboten werden.</li>
                      </ul>
                      <p >4.1.2	Wir verwenden auf unserer Webseite folgende Cookies:</p>
                      <div className={col12}>
                <StaticImage
                  src="../assets/Cookies-Datenschutzerklärung.png"
                  alt=""
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
                      <p >4.1.3	Einschränkung und Löschung von Cookies</p>
                      <p>Sie haben das Recht und die Möglichkeit über die Einstellung Ihres Browsers die 
                        Installation von Cookies einzuschränken bzw. zu verhindern. Ferner können sie jederzeit 
                        bereits gespeicherte Cookies löschen. Die jeweiligen Schritte und Maßnahmen, wie Sie die 
                        Cookies löschen, hängt von Ihrem konkret genutzten Internet-Browser ab.</p>
                       */}

                {/*  <h3 style={{ marginTop: 16 }}>5.	Drittanbieter-Inhalte</h3>
                      <h3 style={{ color:"black" }}>5.1	Google Maps</h3>
                      <p>Wir haben auf unserer Website im Footer einen Button mit einem Link zu Google Maps eingebaut. 
                        Somit Sie unseren Standort auf einer Karte besser bestimmen können. Diesen Link haben wir mit 
                        „Google Maps“ entsprechend gekennzeichnet. Die Nutzungsbedingungen von Google Maps finden sie 
                        unter: <a href="https://www.google.com/intl/de_de/help/terms_maps/">Nutzungsbedingungen von Google Maps.</a></p>
                      <h3 style={{ color:"black" }}>5.3	Wir nutzen zur Webanalyse</h3>
                      <p >5.3.1	Google Analytics</p>
                      <p>Auf dieser Webseite nutzen wir Google Analytics das zur Analyse des Nutzungsverhaltens 
                        unseres Internetauftritts dient. Es handelt sich hierbei um eine Dienstleistung, die von Google 
                        Ireland Limited zur Verfügung gestellt wird, einer nach irischem Recht eingetragenen und be-triebenen 
                        Gesellschaft (Registernummer: 368047) mit Sitz in Gordon House, Barrow Street, Dublin 4, Ireland 
                        (“Google”).</p>
                        <p>Google garantiert durch die Zertifizierung nach dem EU-US-Datenschutzschild 
                          <a href=" https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Activ">
                            (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active)</a>, dass die 
                          Datenschutzvorgaben der Europäischen Union bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
                          <p>Es werden die Nutzungs- und nutzerbezogene Informationen, insb. die IP-Adresse, der Ort, die Zeit 
                            oder die Häufigkeit des Besuchs unseres Internetauftritts an ein Google Server in den USA 
                            übertragen und gespeichert. Im Rahmen von Google Analytics wird ferner die sog. 
                            Anonymisierungsfunktion verwendet, welche die IP-Adresse bereits innerhalb der EU bzw. EWR kürzt.</p>
                            <p>Die auf diesem Wege erhobenen Daten werden von Google genutzt, um uns eine Auswertung über den Besuch unserer 
                              Webseite sowie über die Nutzungsaktivitäten zur Verfügung zu stellen.</p>
                              <p>Die Rechtsgrundlage für die Nutzung von Google Analytics ergibt sich aus Art. 6 I f) DS-GVO. Das berechtigte 
                                Interesse sehen wir in der Analyse, Optimierung und dem Betrieb un-serer Webpräsenz.</p>
                                <p>Schließlich gibt Google an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Hierzu stellt 
                                  Google weitere datenschutzrechtliche Informationen zur Verfügung, die Sie unter:
                                  <a href="https://google.com/intl/de/policies/privacy/partners"> https://google.com/intl/de/policies/privacy/partners </a> aufrufen können. 
                                   Weiter stellt Google ein Deaktivierungs-Add-On zu Verfügung, welches sich auf den 
                                   üblichen Internet-Browsern installieren lässt. Den Link zum Deaktivierungs-Add-On finden Sie hier: </p>
                                   <p style={{ marginBottom: 64 }}><a href="https://tools.google.com/dlpage/gaoptout?hl=de"> https://tools.google.com/dlpage/gaoptout?hl=de.</a> Dieses Add-On stellt sicher, dass die 
                                     Informationen zum Besuch unserer Webseite nicht an Google Analytics übermittelt werden.</p>*/}

                <h3 style={{ marginTop: 16 }}>
                  5. Verarbeitung Ihrer Daten im Falle einer Bewerbung
                </h3>
                <p>
                  Sie können sich über unsere Webseite für offene Stellen
                  bewerben oder uns auch Initiativbewerbungen zukommen lassen.
                  Im Rahmen des Bewerbungsverfahrens werden Ihre Bewerbungsdaten
                  von uns elektronisch verarbeitet.
                </p>
                <p>
                  Die Verarbeitung der Bewerberdaten erfolgt lediglich zur
                  Erfüllung unserer (vor-)vertraglichen Verpflichtungen i.R.d.
                  Bewerbungsverfahrens gem. Art. 88 Abs. 1, Art. 6 Abs. 1 lit. b
                  DSGVO/ Art. 31 DSG.
                </p>
                <p>
                  Um garantieren zu können, dass Ihre Bewerbung ausschließlich
                  von der berechtigten Person unseres Unternehmens bearbeitet
                  wird, bitten wir Sie, die vorgesehene Bewerbungsplattform zu
                  nutzen oder die Kontaktadresse zu nutzen, die sie auf unserer
                  Unterseite „Jobs“ finden.
                </p>
                <p>
                  Im Falle einer Zurückweisung / Absage werden Ihre Unterlagen
                  unverzüglich gelöscht bzw. vernichtet, wobei stets die
                  gesetzlich festgelegte Mindestsicherheitsstufe bei der
                  Vernichtung der Unterlagen eingehalten wird. Ausnahmsweise
                  erfolgt keine sofortige Löschung, sofern die Daten aufgrund
                  gesetzlicher Bestimmungen (u.a. wegen einer Beweispflicht
                  i.R.d. AGG) eine längere Speicherung von bis zu 4 Monaten oder
                  bis zum Abschluss eines gerichtlichen Verfahrens erfordern.
                  Die Rechtsgrundlage hierfür ergibt sich aus Art. 6 Abs. 1 lit.
                  f DSGVO / Art. 31 DSG i.V.m. § 24 Abs. 1 Nr. 1 BDSG.{" "}
                </p>
                <p>
                  Unser berechtigtes Interesse liegt in der Rechtsverteidigung
                  und Rechtsdurchsetzung. Sofern wir Ihre Bewerbung für einen
                  längeren Zeitraum aufbewahren, um Sie zu einem späteren
                  Zeitpunkt erneut auf berufliche Perspektiven bei uns im
                  Unternehmen anzusprechen, so geschieht dies nur, wenn sie uns
                  zuvor Ihre Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO / Art.
                  31 DSG erteilt haben. Auch hier haben Sie das Recht, Ihre
                  Einwilligung jederzeit nach Art. 7 Abs. 3 DSGVO durch Abgabe
                  einer Erklärung uns gegenüber mit Wirkung für die Zukunft zu
                  widerrufen.
                </p>
                <p>
                  Im Falle einer Zusage und folglich einem Abschluss eines
                  Arbeitsvertrages, speichern wir Ihre uns im Rahmen der
                  Bewerbung übermittelten Daten zum Zwecke üblicher Verwaltungs-
                  und Organisationsprozesse. Die Rechtsgrundlage ergibt sich
                  hierfür aus Art. 6 Abs. 1 lit. b/, Art. 88 Abs. 1 DSGVO/ Art.
                  31 DSG i.V.m. § 26 BDSG.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DatenschutzPage;
